import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const { size0, lineHeight7 } = tokens.typography
const { cornerRadius4, xsMax } = tokens.layout
const { hpBlue7, gray3 } = tokens.color
export const ContainerII = styled.div`
  color: ${({ theme }) => theme.colorForegroundMedium};
  font-family: ${({ theme }) => theme.fontTextRegular};
  background-color: ${({ theme }) => theme.colorBackgroundContainer};
  width: 100%;
  .insinksubid {
    display: block;
    font-size: ${size0};
  }

  #instantInkSubscriptionAccordion {
    margin-bottom: ${lineHeight7};
    [aria-expanded] {
      svg > path {
        fill: ${hpBlue7};
      }
    }
    > div {
      border-radius: ${cornerRadius4};
      box-shadow: rgba(33, 33, 33, 0.2) 0px 1px 4px 1px;
      > div:first-child {
        padding: 1rem 1rem;
        align-items: flex-start;
      }

      > div:nth-child(2) {
        border-bottom: 1px solid ${gray3};
        margin: 0;
        padding: 0 16px 16px;
      }
      [aria-label^='Chevron Down'] {
        fill: #0278ab;
        margin-bottom: 21px;
      }
      [aria-hidden^='false'] {
        margin: 0px;
        padding: 1rem 1rem;
      }
    }
  }
`
export const PanelII = styled.div`
  display: flex;
  flex-direction: column;
`
export const AccordionHeaderII = styled.div`
  font-family: ${({ theme }) => theme.fontTextRegular};
  color: ${({ theme }) => theme.colorForegroundMedium};
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`
export const SubscriptionDescII = styled.div`
  diplay: flex;
  flex-flow: column;
  width: 100%;
  > span {
    display: block;
  }
  .links-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: ${xsMax}) {
      flex-wrap: wrap;
      flex-direction: row;
      gap: 8px;
    }
  }
`
export const SubscriptionTotalII = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  align-self: flex-end;
`
export const EditLinkII = styled.div`
  margin-bottom: 7px;
`
export const PaymentDetailsII = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  @media screen and (max-width: ${xsMax}) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .last-4-digits {
    color: ${tokens.color.gray9};
    text-align: right;
    font-size: ${tokens.typography.size0};
    line-height: ${tokens.typography.lineHeight1};
    margin-right: 8px;
  }
`

export const GPayPaymentII = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 4px;
`
export const ProgressContainerII = styled.div`
  padding-bottom: 15px;
  width: 100%;
`
