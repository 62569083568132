import { CreditCardInfo } from '@/types/CreditCardInfo'
import Images from '@/assets/images'

const CardList = {
  american_express: {
    cardName: 'Amex',
    cardImage: Images.imgAmex,
    testid: 'cardImg-Amex'
  },

  discover: {
    cardName: 'Discover',
    cardImage: Images.imgDiscover,
    testid: 'cardImg-Discover'
  },
  mastercard: {
    cardName: 'MasterCard',
    cardImage: Images.imgMastercard,
    testid: 'cardImg-Mastercard'
  },
  visa: {
    cardName: 'Visa',
    cardImage: Images.imgVisa,
    testid: 'cardImg-Visa'
  }
}

export default function getHpAllInPaymentCardInfo(
  creditCardType: string
): CreditCardInfo {
  return (
    CardList[creditCardType] ?? {
      cardName: creditCardType,
      cardImage: Images.generic,
      testid: 'cardImgGeneric'
    }
  )
}
