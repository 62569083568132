import React, { useEffect, useState } from 'react'
import useGetText from '@/hooks/useGetText'
import { HeaderContentStyle, NextPaymentLabel, StyledImage } from './styles'
import { useCommerceInfo } from '@/hooks/useCommerceInfo/useCommerceInfo'
import { formatStringDate } from '@/helpers/formatStringDate'
import useSettings from '@/hooks/useSettings'
import { PaymentClient } from '@monetization/hpaip-notification-rules-react'
import getHpOnePaymentCardInfo from '@/helpers/getHpAllInPaymentCardInfo'
import HpAipLinks from '../HpAipLinks/HpAipLinks'

export default function HpAipHeader(props) {
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false)
  const [paymentData, setPaymentData] = useState<any>(' ')
  const getText = useGetText('SubscriptionManagement')
  const { assetsProvider } = useSettings()
  const language = assetsProvider.getLanguage()
  const country = assetsProvider.getCountry()
  const locale = `${language}-${country}`
  const corePlan = props.corePlan
  const printerData = props.mainProps.printerProps
  const returnDetails =
    props.mainProps.commonNotification?.notificationApiInfo?.returnDetails
  const subObj = printerData?.root
  const { authProvider, stack } = props.mainProps.commonProps
  const { data: commerceInfo, isLoading: commerceInfoLoading } =
    useCommerceInfo({ subObj })
  const billingDate = commerceInfo?.billingDate

  const nextPaymentDue =
    !commerceInfoLoading && billingDate
      ? `${getText('nextPaymentDue')}: ${formatStringDate(billingDate, locale)}`
      : ''

  const paymentClient = React.useMemo(
    () => new PaymentClient(authProvider, stack),
    [authProvider, stack]
  )

  useEffect(() => {
    const fetchPaymentData = async () => {
      setPaymentLoading(true)
      try {
        const PaymentDetails = await paymentClient.getPaymentDetails(
          printerData?.root?.paymentMethodId
        )

        setPaymentData(PaymentDetails)
        setPaymentLoading(false)
      } catch (error) {
        if (error?.response?.status === 404) return setPaymentData(null)

        setPaymentLoading(false)
        console.error('Error:', error)
      }
    }
    if (
      printerData?.printer !== null &&
      printerData?.root?.paymentMethodId &&
      printerData?.called &&
      !printerData.error
    ) {
      fetchPaymentData()
    } else {
      setPaymentData(null)
    }
  }, [paymentClient, printerData])

  const paymentMethodDetails = paymentData?.payment_method_details
  const creditCardTypeInfo = getHpOnePaymentCardInfo(
    paymentMethodDetails?.card_type
  )

  const HeaderContent = paymentMethodDetails && (
    <>
      <div className="paymentInfo-top">{corePlan?.title}</div>
      {nextPaymentDue && <NextPaymentLabel>{nextPaymentDue}</NextPaymentLabel>}
      <div className="paymentInfo-bottom">
        <div className="pib-left">
          <HpAipLinks
            printerData={printerData}
            commonProps={props.mainProps.commonProps}
            returnDetails={returnDetails}
          />
        </div>
        <div className="pib-right">
          <StyledImage
            src={creditCardTypeInfo?.cardImage}
            alt={creditCardTypeInfo?.cardName}
          />
          XXXX-{paymentMethodDetails?.last_four}
        </div>
      </div>
    </>
  )

  return (
    <HeaderContentStyle className="caption">
      {!commerceInfoLoading && !paymentLoading && HeaderContent}
    </HeaderContentStyle>
  )
}
