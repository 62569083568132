import useGetText from '@/hooks/useGetText'
import React from 'react'
import { LinksContainer, TextDividerStyle } from './styles'
import { InstantInkSubscription } from '@/types/subscription-response'
import getLocation from '@/helpers/getLocation'
import { CardLink, CardLinkWrapper } from '@/components/style'
import {
  CancelSubscriptionButtonClicked,
  ManageSubscriptionButtonClicked,
  publishEvent
} from '@/utils/analytics'

export function InstantInkManagementLinks(props) {
  const { subscription, commonProps } = props
  const { language, country } = getLocation()
  const getText = useGetText('SubscriptionManagement')
  const hasUcdeInPath = window?.location?.pathname?.includes('/ucde') || false
  const removeUCDE = hasUcdeInPath ? '/ucde' : ''
  const accid = `?acc_id=${subscription.accountIdentifier}`
  const printPlansHref = `/${country}/${language}${removeUCDE}/print_plans${accid}`
  const cancelPlanHref = `/${country}/${language}${removeUCDE}/print_plans/cancellation${accid}`

  const disabledStates = ['initiated_unsubscribe', 'unsubscribed', 'obsolete']

  const isDisabled = (subscription: InstantInkSubscription): boolean => {
    return disabledStates.includes(subscription.state)
  }

  const handleManageSubscriptionClick = () => {
    publishEvent(ManageSubscriptionButtonClicked, { subscription })
  }

  const handleCancelSubscriptionClick = () => {
    publishEvent(CancelSubscriptionButtonClicked, { subscription })
  }

  return (
    <LinksContainer>
      <span>
        <CardLink
          className="label-small"
          tabIndex="0"
          id="instant-ink-manage-subscription-link-btn"
          data-testid="instant-ink-manage-subscription-link"
          commonProps={commonProps}
          path={printPlansHref}
          onClick={handleManageSubscriptionClick}
          text={
            <CardLinkWrapper id="manage-subscription-button">
              {getText('manageSubscription')}
            </CardLinkWrapper>
          }
        />
      </span>
      <TextDividerStyle className="label-small"> {` | `}</TextDividerStyle>
      <span>
        <CardLink
          data-testid="instant-ink-cancel-subscription-link"
          className="label-small"
          tabIndex="0"
          id="instant-ink-cancel-subscription-link-btn"
          commonProps={commonProps}
          disabled={isDisabled(subscription)}
          path={cancelPlanHref}
          onClick={handleCancelSubscriptionClick}
          text={
            <CardLinkWrapper id="cancel-subscription-button">
              {getText('cancelSubscription')}
            </CardLinkWrapper>
          }
        />
      </span>
    </LinksContainer>
  )
}
