export interface SubscriptionType {
  Id?: string
  blId?: string
  subscriptionId: string
  friendlySubscriptionId: string
  tenantId: string
  country?: string
  state?: string
  entities: Array<SubscriptionEntity>
  blManifestVersion?: string
  hybridIndex?: number
  controllerId?: string
  paymentMethodId?: string
  createdAt?: string
  updatedAt?: string
}

interface History {
  timeStamp: string
  status: string
}

interface Part {
  serialNumber: string
  status: string
  timeStamp: string
  waybillGenerated: boolean
  modelSku: string
  history: History[]
}
export interface RetrieveReturnResponse {
  subscriptionId: string
  returnOrderId: string
  fulfillmentOrderId: string
  tenantId: string
  trackingNumber?: string
  returnNumber: string
  eClaimsID: string
  createdAt: string
  timeoutInDays: number
  returnReason: string
  parts: Part[]
  invoiceNumber?: string
  invoiceCurrency?: string
  invoiceTotal?: string
}

export enum pimProductType {
  pc = 'pc',
  accessories = 'accessories',
  thirdPartyOffer = '3PO',
  carepack = 'carepack'
}

export enum mblEntityType {
  pc = 'iot-pc',
  chromebook = 'chromebook',
  printer = 'iot-printer',
  keyboard = 'keyboard',
  mouse = 'mouse',
  pen = 'pen',
  paper = 'instant-paper',
  software = 'software',
  carepack = 'carepack',
  printplan = 'instant-ink'
}

export enum deviceGroup {
  MAINDEVICE,
  ACCESSORY,
  PRINTPLAN,
  OTHER
}

export interface SubscriptionCommerce {
  type?: string
  value?: {
    subscriptionId?: string
    friendlySubscriptionId?: string
  }
}

export type SubscriptionEntity = {
  entityId: string
  userId: string
  shippingAddressId: string
  state: string
  entityType: string
  entityStartDate?: string
  entityEndDate?: string
  linkedEntityIds?: Array<string>
  product: EntityProduct
  source: EntitySource
  fulfillment?: Fulfillment
  entityDetails?: EntityDetails
  cancellation?: SubscriptionCancellation
  commerce?: SubscriptionCommerce
  linkedEntities?: Array<LinkedEntity>
  createdAT?: string
  updatedAt?: string
}

type LinkedEntity = {
  type: string
  value: string
}
export interface SubscriptionCancellation {
  enabled: boolean
  locked: boolean
  type?: string //'remorsePeriod' | 'paymentPeriod'
  value?: {
    validUntil?: string
  }
}

export interface Fulfillment {
  type: string
  value?: {
    fulfillmentOrderId?: string
    obId?: string
    threePoController?: string
    shippedDate?: string
  }
}

type EntityDetails = {
  accessoryId?: string
  serialNumber?: string
  uniqueDeviceId?: string
  pendingAssociationId?: string
  pendingAssociationState?: string
}
type EntityProduct = {
  type: string
  value?: Record<string, unknown>
}
type EntitySource = {
  type: string
  value?: Record<string, unknown>
}

export type DeviceCache = {
  deviceId: string
  type: string
  identity?: {
    friendlyName?: string
    serialNumber?: string
    makeAndModel: {
      name: string
      number?: string
      sku?: string
      series?: string
    }
  }
  images: {
    url: string
  }[]
}

export type Device = {
  uuid?: string
  state?: string
  createdAt?: string
  name: string
  friendlyName?: string
  image?: string
  productNumber: string
  serialNumber?: string
  eligibleForRefresh?: string
  type: string
  entityDetails?: EntityDetails
  entityType?: mblEntityType
}

export type AddOn = {
  name: string
  image?: string
  friendlyName?: string
  entityStartDate?: string
  pagePerMonth?: number
  type: string
}

export type DeviceGrant = {
  deviceCloudId: string
  grant: string
  level: string
  tenantId: string
  userId: string
}

export type DeviceCacheResponse = DeviceCache

export type SubscriptionResponse = Array<SubscriptionType>

export type SubscriptionState =
  | 'subscribed'
  | 'paused'
  | 'pending'
  | 'updating'
  | 'error'
  | 'deactivating'
  | 'inactive'

export enum SubscriptionStateEnum {
  SUBSCRIBED = 'subscribed',
  STOPPING = 'stopping',
  PAUSED = 'paused',
  PENDING = 'pending',
  PENDING_RETURN = 'pending-return',
  RETURNED = 'returned',
  UPDATING = 'updating',
  ERROR = 'error',
  RESOLVED = 'resolved',
  OVERDUE = 'overdue',
  SUSPENDED = 'suspended',
  NOLONGERACTIVE = 'noLongerActive',
  ACTIVE = 'active',
  DEACTIVATING = 'deactivating',
  INACTIVE = 'inactive',
  CANCELED = 'inactive',
  CANCELING = 'deactivating'
}

export enum allowedToShowNotificationStates {
  received = 'received',
  cancelling = 'cancelling',
  cancelled = 'cancelled',
  pending = 'pending',
  'pending-return' = 'pending-return',
  returned = 'returned',
  resolved = 'resolved',
  overdue = 'overdue',
  nolongeractive = 'nolongeractive',
  about_to_expire = 'about_to_expire',
  expired = 'expired',
  inactive = 'inactive',
  deactivating = 'deactivating'
}

export enum notOnboardedStates {
  open = 'open',
  pending = 'pending'
}

export enum activeState {
  pending = 'pending',
  subscribed = 'subscribed',
  cancelling = 'cancelling',
  updating = 'updating',
  resolved = 'resolved',
  overdue = 'overdue',
  suspended = 'suspended',
  nolongeractive = 'noLongerActive'
}

export enum SubscriptionReturnStatusEnum {
  NEW = 'new',
  INITIATED = 'initiated',
  PROCESSING = 'processing',
  COMPLETE_SUCCESSFUL = 'completeSuccessful',
  COMPLETE_TIMEOUT = 'completeTimeout',
  COMPLETE_UNSUCCESSFUL = 'completeUnsuccessful',
  TIMEOUT = 'timeout',
  ITEMS_RECEIVED = 'itemReceived',
  RESOLVED = 'resolved',
  OVERDUE = 'overdue',
  SUSPENDED = 'suspended',
  NO_LONGER_ACTIVE = 'noLongeractive'
}

export type ExpirationStatus = 'about_to_expire' | 'expired'
