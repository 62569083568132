import { useState, useEffect } from 'react'
import { SubscriptionsStateClient } from '@monetization/hpaip-notification-rules-react'
import { SubscriptionType } from 'src/types/Subscription'

const useGetAllSubscriptions = (authProvider, stack) => {
  const [data, setData] = useState<SubscriptionType | null>(null)
  const [isFetching, setIsFetching] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchSubscriptions = async () => {
      setIsFetching(true)
      try {
        const client = new SubscriptionsStateClient(authProvider, stack)
        const fetchedData = await client.getAllSubscriptions()
        setData(fetchedData)
        setError(false)
      } catch (err) {
        console.error('Error fetching subscriptions:', err)
        setError(true)
      } finally {
        setIsFetching(false)
      }
    }

    fetchSubscriptions()
  }, [authProvider, stack])

  return { data, isFetching, error }
}
export default useGetAllSubscriptions
