import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const { size5, size4, xsMax } = tokens.layout

export const LoadingStyle = styled.div`
  margin-bottom: ${size5};
  display: flex;
  width: 100%;
  align-self: center;
  > div {
    min-height: 90px;
  }
`

export const AccordionHeader = styled.div`
  font-family: ${({ theme }) => theme.fontTextRegular};
  color: ${({ theme }) => theme.colorForegroundMedium};
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`

export const NotificationSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: unset;
  margin: 0 0;
  .multipleNotification {
    width: 100%;
    margin-bottom: -2px;
  }
`

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
`

export const HpAipDesc = styled.div`
  diplay: flex;
  flex-flow: column;
  width: 100%;
  > span {
    display: block;
  }
  .links-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`

export const MyPlanContainer = styled.div`
  display: grid;
  gap: ${size4};
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @media screen and (max-width: ${xsMax}) {
    grid-template-columns: auto;
  }
  & span > svg.iconLabel {
    margin-right: 5px;
    vertical-align: sub;
  }
`
