import { SubscriptionsClient } from '@monetization/hpaip-notification-rules-react'
import { getStack } from '../../../src/helpers/getStack'
import environments from '../../../env.json'

type PatchSubscriptionFn = (
  subscriptionId?: string,
  data?: Record<string, any>
) => Promise<void>

export type SubscriptionResultTuple = {
  patch: PatchSubscriptionFn
}

const useSubscription = ({ authProvider, stack }): SubscriptionResultTuple => {
  const currentStack = getStack(stack)
  const envVariables = environments.find((e) => e.stack === currentStack)
  const ONBOOKS_BL_ID = envVariables?.variables?.ONBOOKS_BL_ID
  /**
   * Method to update subscription state and entities
   */
  const patch = async (
    subscriptionId: string,
    data: Record<string, any>
  ): Promise<any> => {
    const webClient = new SubscriptionsClient(authProvider, stack)

    const res = await webClient.update(subscriptionId, ONBOOKS_BL_ID, data)
    return res?.data
  }

  return { patch }
}

export default useSubscription
