import React, { useState } from 'react'
import useGetText from '@/hooks/useGetText'
import useShellContext from '@/hooks/useShellContext'
import {
  mblEntityType,
  SubscriptionReturnStatusEnum,
  SubscriptionStateEnum
} from '@/types/Subscription'
import {
  CancelSubscriptionButtonClicked,
  ManageSubscriptionButtonClicked,
  publishEvent
} from '@/utils/analytics'
import { StyledHeaderActionButton, TextDividerStyle } from './style'
import getLocation from '@/helpers/getLocation'
import useSubscription from '@/hooks/useSubscription'
import { getCurrentOfferPayload } from '@/helpers/getCurrentOfferPayload'
import HpAipBuyerRemorseModal from './HpAipBuyerRemorseModal'
import HpAipConfirmCancellationModal from './HpAipConfirmCancellationModal'
import { CardLink, CardLinkWrapper } from '@/components/style'

export default function HpAipLinks(props) {
  const getText = useGetText('SubscriptionManagement')
  const [showBuyerRemorseModal, setShowBuyerRemorseModal] = useState(false)
  const [error, setError] = useState(false)
  const [showConfirmCancellationModal, setShowConfirmCancellationModal] =
    useState(false)
  const printerData = props.printerData
  const returnDetails = props.returnDetails
  const { language, country } = getLocation()
  const { authProvider, stack } = useShellContext()
  const subscriptionService = useSubscription({ authProvider, stack })
  const hasUcdeInPath = window?.location?.pathname?.includes('/ucde') || false
  const removeUCDE = hasUcdeInPath ? '/ucde' : ''
  const subscription = printerData?.root

  const payload = printerData?.optionsData
    ? getCurrentOfferPayload(printerData?.optionsData)
    : null
  const showCancelPlan = printerData?.cancellationInfo?.showCancelPlan ?? false
  const showCancellationStatus =
    printerData?.cancellationInfo?.showCancellationStatus ?? false
  const buttonDisable = printerData?.cancellationInfo?.buttonDisable ?? false
  const managementHref =
    printerData?.printer?.entityType === mblEntityType.pc ||
    printerData?.printer?.entityType === mblEntityType.chromebook
      ? `/${country}/${language}${removeUCDE}/hp-all-in-plan`
      : `/${country}/${language}${removeUCDE}/hp-all-in-print-plan/overview`

  const cancelPlanHref = `/${country}/${language}${removeUCDE}/hp-all-in-print-plan/cancellation/${printerData?.subscriptionId}`

  const showCancelLinkStates = [
    SubscriptionStateEnum.ACTIVE,
    SubscriptionStateEnum.PENDING,
    SubscriptionStateEnum.DEACTIVATING,
    SubscriptionStateEnum.SUSPENDED
  ]

  const handleManageSubscriptionClick = () => {
    publishEvent(ManageSubscriptionButtonClicked, { subscription })
  }

  const handleCancelSubscriptionClick = () => {
    publishEvent(CancelSubscriptionButtonClicked, { subscription })
  }

  const showFirstHourCancellation =
    (printerData?.cancellationInfo?.firstHourCancellation ?? false) &&
    !printerData?.printer?.fulfillment

  const inActiveState =
    printerData?.root?.state === SubscriptionStateEnum.INACTIVE

  const checkReturnStatus = () => {
    const returnStatus =
      returnDetails?.parts?.find(
        (part) =>
          part.modelSku === printerData?.printer?.product?.value?.productSku
      )?.status ?? false
    const hideCancelButtonStatuses = [
      SubscriptionReturnStatusEnum.TIMEOUT,
      SubscriptionReturnStatusEnum.COMPLETE_TIMEOUT,
      SubscriptionReturnStatusEnum.COMPLETE_UNSUCCESSFUL
    ]

    const hideCancelButton = hideCancelButtonStatuses.includes(
      returnStatus as SubscriptionReturnStatusEnum
    )

    if (
      printerData?.printer?.state === SubscriptionStateEnum.DEACTIVATING &&
      hideCancelButton
    ) {
      return false
    }
    return true
  }

  const handleConfirmCancellation = () => {
    subscriptionService
      .patch(printerData?.root?.subscriptionId, payload)
      .then(() => {
        setShowConfirmCancellationModal(true)
        setShowBuyerRemorseModal(false)
      })
      .catch(() => {
        setError(true)
        setShowBuyerRemorseModal(false)
      })
  }

  const handleCloseConfirmation = () => {
    setShowConfirmCancellationModal(false)
    window.location.reload()
  }

  const handleCloseBuyerRemorse = () => {
    setShowBuyerRemorseModal(false)
    setError(false)
  }

  return (
    <>
      <span>
        <CardLink
          data-testid="manage-subscription-link-btn"
          tabindex="0"
          id="all-in-plan-manage-subscription-link-btn"
          commonProps={props.commonProps}
          path={managementHref}
          disabled={showFirstHourCancellation || inActiveState}
          onClick={handleManageSubscriptionClick}
          text={
            <CardLinkWrapper id="manage-plan-button">
              {getText('managePlan')}
            </CardLinkWrapper>
          }
        />
      </span>
      {showCancelLinkStates.includes(
        printerData?.printer?.state as SubscriptionStateEnum
      ) && (
        <>
          {showFirstHourCancellation ? (
            <>
              <TextDividerStyle>{`  |  `}</TextDividerStyle>
              <span>
                <StyledHeaderActionButton
                  onClick={() => setShowBuyerRemorseModal(true)}
                  data-testid="buyer-remorse-cancel-link-btn"
                  tabindex="0"
                  id="buyer-remorse-cancel-link-btn"
                >
                  {getText('cancelPlan')}
                </StyledHeaderActionButton>
              </span>
            </>
          ) : (
            checkReturnStatus() && (
              <>
                {(showCancellationStatus || showCancelPlan) && (
                  <TextDividerStyle> {`  |  `}</TextDividerStyle>
                )}
                <span>
                  <CardLink
                    data-testid="cancel-subscription-link-btn"
                    tabIndex="0"
                    id="all-in-plan-cancel-subscription-link-btn"
                    commonProps={props.commonProps}
                    path={cancelPlanHref}
                    disabled={buttonDisable}
                    onClick={handleCancelSubscriptionClick}
                    text={
                      <CardLinkWrapper id="cancel-subscription-button">
                        {showCancellationStatus &&
                          getText('cancellationStatus')}
                        {showCancelPlan && getText('cancelPlan')}
                      </CardLinkWrapper>
                    }
                  />
                </span>
              </>
            )
          )}
        </>
      )}
      <HpAipBuyerRemorseModal
        showBuyerRemorseModal={showBuyerRemorseModal}
        handleCloseBuyerRemorse={handleCloseBuyerRemorse}
        handleConfirmCancellation={handleConfirmCancellation}
        error={error}
      />
      <HpAipConfirmCancellationModal
        showConfirmCancellationModal={showConfirmCancellationModal}
        handleCloseConfirmCancellation={handleCloseConfirmation}
      />
    </>
  )
}
