import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const { size1, lineHeight1 } = tokens.typography
const { size3 } = tokens.layout
export const PaymentInfoStyle = styled.div`
  font-size: ${size1};
  line-height: ${lineHeight1};
  color: ${tokens.color.gray9};
  display: flex;
  width: 100%;
  flex-flow: column;
  div {
    display: flex;
  }

  .paymentInfo-top {
    flex-direction: column;
    gap: 4px;
  }

  .paymentInfo-bottom {
    font-size: ${size1};
    div {
      align-items: center;
    }
    justify-content: space-between;

    .pib-right {
      font-size: ${size3};
      color: ${tokens.color.gray9};
    }

    @media screen and (min-width: 370px) {
      flex-wrap: wrap;
      .pib-left {
        width: 100%;
        min-width: 170px;
      }
      .pib-right {
        justify-content: flex-end;
        width: 100%;
        margin-top: 8px;
      }
    }
    @media screen and (min-width: 640px) {
      .pib-left {
        width: auto;
      }
      .pib-right {
        width: auto;
      }
    }
  }
`
