import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const { highContrastGray } = tokens.color
const { size1, size4 } = tokens.layout
const { lineHeight2 } = tokens.typography

interface StyledHeaderActionProps {
  isDisabled?: boolean
}

export const LinksContainer = styled.span`
  padding-top: 5px;
`
export const StyledHeaderActionLink = styled.a<StyledHeaderActionProps>`
  background: transparent;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: none;
  }
  &:visited,
  &:link {
    color: #0073a8;
  }
  && {
    color: ${(props) => (props.isDisabled ? highContrastGray : '#0073a8')};
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
    cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  }
`
export const StyledHeaderActionButton = styled.button<StyledHeaderActionProps>`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  color: ${(props) => (props.isDisabled ? highContrastGray : '#0073a8')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};

  &:hover {
    text-decoration: none;
  }
`
export const TextDividerStyle = styled.span`
  padding: 0 ${size1};
  font-weight: 400;
  font-size: ${size4} !important;
  line-height: ${lineHeight2};
`
