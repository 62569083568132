import React from 'react'
import {
  StyledCard,
  StyledIconPrinter,
  StyledCardContainer,
  DeviceDetailStyles
} from './style'
import IconPrinter from '@veneer/core/dist/esm/scripts/icons/icon_printer'
import {
  CardError,
  DeviceDetail,
  DeviceImage,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import useGetText from '@/hooks/useGetText'
import moment from 'moment'
import { isDeliquency } from '@/utils/common-util'

export default function HpAipDevice(props) {
  const getText = useGetText('SubscriptionManagement')
  const {
    printerProps,
    commonNotification,
    corePlan,
    productSerialNumber,
    disableCard,
    isLoading,
    isError,
    isPreArrival,
    duration
  } = props
  const isActive = isDeliquency(commonNotification?.deliquencyStatus)

  return (
    <StyledCard
      $disabled={isLoading ? false : (disableCard && !isError) || isActive}
    >
      <StyledCardContainer
        $isLoading={isLoading}
        full="true"
        icon={<IconPrinter size={32} color={'gray9'} />}
        title={corePlan?.title ? corePlan?.title : ''}
        aside={
          !isError &&
          !isLoading && (
            <DeviceImage
              deviceImage={corePlan?.productimage}
              modelName={corePlan?.title}
              serialNumber={productSerialNumber}
              type="iot-printer"
            />
          )
        }
      >
        <LoadingHandler
          loading={isLoading}
          error={isError}
          $position="absolute"
          loadingText={getText('master-device-detail.loading', {
            defaultValue: 'Loading...'
          })}
          customError={
            <CardError
              disabled={disableCard}
              icon={<StyledIconPrinter size={72} />}
              texts={
                isPreArrival
                  ? [
                      getText('device-details.pre-arrival', {
                        defaultValue:
                          'Your device details will be displayed here after you set up the printer'
                      })
                    ]
                  : [
                      <>
                        <p>
                          {getText('device-details.error.title.paragraph-1', {
                            defaultValue: 'Something went wrong.'
                          })}
                        </p>
                        <p>
                          {getText('device-details.error.title.paragraph-2', {
                            defaultValue: "We couldn't load your plan details."
                          })}
                        </p>
                      </>,
                      getText('device-details.error.description', {
                        defaultValue: 'Please refresh the page.'
                      })
                    ]
              }
            />
          }
        >
          <DeviceDetailStyles>
            <DeviceDetail
              t={getText}
              productNumber={corePlan?.sku ? corePlan?.sku.split('#')[0] : ''}
              serialNumber={productSerialNumber}
              eligiblePrinterRefreshDate={moment(
                printerProps?.printer?.entityStartDate.split('T')[0]
              )
                .add(duration, 'y')
                .format('MMM DD, YYYY')}
            />
          </DeviceDetailStyles>
        </LoadingHandler>
      </StyledCardContainer>
    </StyledCard>
  )
}
