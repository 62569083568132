export const sortInstantInkSubscriptions = (subscriptions) => {
  const isObsolete = (subscription) => subscription.state === 'obsolete'

  const compareCreationDates = (a, b) => {
    const dateA = new Date(a.subscription.createdAt).getTime()
    const dateB = new Date(b.subscription.createdAt).getTime()
    return dateB - dateA
  }

  return subscriptions.sort((a, b) => {
    const isAObsolete = isObsolete(a.subscription)
    const isBObsolete = isObsolete(b.subscription)

    if (isAObsolete && !isBObsolete) return 1
    if (!isAObsolete && isBObsolete) return -1

    return compareCreationDates(a, b)
  })
}
