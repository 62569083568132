import React, { useEffect, useState, useMemo } from 'react'
import HpAipAccordion from '../HpAipAccordion/HpAipAccordion'
import { getSubscriptionKey } from '@/helpers/uiConvert'
import useGetText from '@/hooks/useGetText'
import { SubscriptionAnalyticsProvider } from '@/context/SubscriptionAnalyticsProvider'
import {
  calculateContractDuration,
  findCoreOfferPlan
} from '../../api/services'
import {
  LoadingStyle,
  AccordionHeader,
  HpAipDesc,
  NotificationSection,
  Panel,
  MyPlanContainer
} from './style'
import Images from '@/assets/images'
import {
  MultipleNotification,
  LoadingHandler
} from '@monetization/hpaip-ui-shared-components'
import { CoreOfferPlanInfo } from '@/types/planInfo'
import { SubscriptionStateEnum } from '@monetization/hpaip-notification-rules-react'
import HpAipHeader from '../HpAipHeader/HpAipHeader'
import HpAipDevice from '../HpAipDevice/HpAipDevice'
import HpAipMyPlan from '../HpAipMyPlan/HpAipMyPlan'
import ErrorCard from '../ErrorCard'
import { APIError } from '../HpAllInOneComponent/styles'

export default function HpAipSection(props) {
  const [corePlan, setCorePlan] = useState<CoreOfferPlanInfo | null>()
  const [updatedNotificationsList, setUpdatedNotificationsList] = useState([])
  const [isAddedPaperNotification, setIsAddedPaperNotification] =
    useState(false)
  const getText = useGetText('SubscriptionManagement')

  const { printerProps, commonNotification } = props
  const graphQLproductData = commonNotification?.graphQLproductData
  const subscription = printerProps?.root
  const productSerialNumber = printerProps?.printer?.entityDetails?.serialNumber
  const hasOptionsdata = !!printerProps?.optionsData?.subscription
  const hasGraphQLData = !!(
    graphQLproductData && graphQLproductData?.length > 0
  )
  const duration = useMemo(() => {
    if (hasGraphQLData) return calculateContractDuration(graphQLproductData)
  }, [graphQLproductData])

  const { disableCard, isPreArrival } = useMemo(() => {
    const disableCardState = [
      SubscriptionStateEnum.CANCELED,
      SubscriptionStateEnum.CANCELING
    ]
    if (!printerProps?.printer)
      return { disableCard: true, disableCardLink: true }
    return {
      disableCard:
        disableCardState.includes(printerProps?.printer?.state) ||
        printerProps?.isCancellationTriggered,
      disableCardLink: disableCardState.includes(printerProps?.printer?.state),

      isPreArrival:
        printerProps?.printer?.state === SubscriptionStateEnum.PENDING ||
        !printerProps?.printer?.entityDetails?.uniqueDeviceId
    }
  }, [printerProps?.printer])
  useEffect(() => {
    if (!printerProps?.printer) return

    const values = printerProps?.printer?.product?.value
    const { parentProductSku, productSku } = values || {}

    if (!parentProductSku || !productSku) return
    if (hasGraphQLData)
      setCorePlan(findCoreOfferPlan(graphQLproductData, productSku))
  }, [printerProps?.printer, graphQLproductData])

  const isLoading = !isPreArrival && (!printerProps?.printer || !corePlan)

  const emptyData = !isPreArrival && !isLoading

  const isError = Boolean(isPreArrival || (isLoading && emptyData))

  const showFirstHourCancellation =
    (printerProps?.cancellationInfo?.firstHourCancellation ?? false) &&
    !printerProps?.printer?.fulfillment

  useEffect(() => {
    if (!isAddedPaperNotification) return
    setUpdatedNotificationsList((prevList) => [
      ...prevList,
      {
        notificationType: 'positive',
        title: 'Your Paper Add-on Service was successfully added',
        description:
          '<p>Congratulations! You will receive an email when your paper is shipped. Your Paper Add-on Service billing will start when you receive your first shipment.</p>',
        notificationID: 'AddPaperSuccess',
        showCloseButton: true
      }
    ])
  }, [isAddedPaperNotification])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const rescindCanel = params.get('rescindPaperCanel')
    if (rescindCanel === 'success') {
      const notificationConfig = {
        notificationID: 'SubscriptionRescind',
        title: 'Your Paper Add-on Service has been successfully resumed',
        description:
          'The details are below. If you experience any issues, please visit <a href="https://support.hp.com/contact/select-product?originid=myaccount" target="_blank"> 24/7 Pro live support.</a>',
        notificationType: 'positive',
        showCloseButton: true
      }

      setUpdatedNotificationsList([notificationConfig])
      const newUrl = window.location.pathname
      if (params.has('rescindPaperCanel')) {
        params.delete('rescindPaperCanel')
      }
      window.history.replaceState({}, '', newUrl)
    }
  }, [commonNotification?.notificationsList])
  const multiNotificationArray = [
    ...(commonNotification?.notificationsList || []),
    ...updatedNotificationsList
  ]

  const headerCentralArea = (
    <div className="header-area">
      <img id="hpOneIcon" src={Images.imgHpOne} alt="logo-hpOne" />
      {subscription?.friendlySubscriptionId && (
        <span className="planid">
          {getText('planId')}: {subscription.friendlySubscriptionId}
        </span>
      )}
      <HpAipHeader mainProps={props} corePlan={corePlan} />
    </div>
  )
  const graphQlProps = {
    duration: duration,
    graphQLproductData: graphQLproductData,
    corePlan: corePlan
  }
  const AccordionBody = !showFirstHourCancellation && (
    <Panel>
      <HpAipDevice
        printerProps={printerProps}
        commonNotification={commonNotification}
        corePlan={corePlan}
        productSerialNumber={productSerialNumber}
        duration={duration}
        disableCard={disableCard}
        isLoading={isLoading}
        isError={isError}
        isPreArrival={isPreArrival}
      />
      {hasOptionsdata ? (
        <MyPlanContainer>
          <HpAipMyPlan
            printerProps={printerProps}
            handleAddedPaperNotification={setIsAddedPaperNotification}
            graphQLproductData={graphQlProps}
            commonProps={props.commonProps}
          />
        </MyPlanContainer>
      ) : (
        <APIError>
          <ErrorCard
            getText={getText}
            subtitle={getText('errorScreen.subtitle')}
          />
        </APIError>
      )}
    </Panel>
  )

  const analyticsProps = {
    analytics: props.analytics,
    subscription: subscription,
    printerData: printerProps
  }

  const subscriptionKey = getSubscriptionKey(subscription)

  return (
    <LoadingStyle>
      <LoadingHandler loading={isLoading}>
        <SubscriptionAnalyticsProvider commonProps={analyticsProps}>
          <HpAipAccordion
            header={{
              centralArea: headerCentralArea,
              previewArea: !showFirstHourCancellation && (
                <AccordionHeader>
                  <HpAipDesc className="caption">
                    {multiNotificationArray.length > 0 && (
                      <NotificationSection>
                        <div className="multipleNotification">
                          <MultipleNotification
                            multiNotificationArray={multiNotificationArray}
                            screenPath="/SubscriptionManagementReact/"
                          />
                        </div>
                      </NotificationSection>
                    )}
                  </HpAipDesc>
                </AccordionHeader>
              ),
              'aria-label': 'subscription',
              id: 'accordion-header'
            }}
            body={AccordionBody}
            subscription={subscription}
            id={`hpOneSubscriptionAccordion-${subscriptionKey}`}
            dataTestid="data-test-hpOneSubscription-accordion"
            expanded={true}
            showFirstHourCancellation={showFirstHourCancellation}
          />
        </SubscriptionAnalyticsProvider>
      </LoadingHandler>
    </LoadingStyle>
  )
}
