export enum DeliquencyNotificationTypes {
  OVERDUE = 'payment_overdue',
  SUSPENDED = 'suspended',
  INACTIVE = 'inactive',
  EXPIRED = 'expired',
  ABOUT_TO_EXPIRED = 'about_to_expire',
  NONE = 'none'
}

export const isDeliquency = (state) => {
  if (
    state === DeliquencyNotificationTypes.INACTIVE ||
    state === DeliquencyNotificationTypes.SUSPENDED
  ) {
    return true
  }
  return false
}
