import React, { useMemo, useState } from 'react'
import {
  CardError,
  AddOnPaperStepOne,
  PaperOnPaasModal,
  LoadingHandler,
  InkPlanDetails
} from '@monetization/hpaip-ui-shared-components'
import useShippingAddress from '@/hooks/useShippingAddress'
import { SubscriptionStateEnum } from '@/types/Subscription'
import useUpdateSubscription from '@/hooks/useUpdateSubscription/useUpdateSubscription'
import { ThemeProvider as StyleThemeProvider } from 'styled-components'
import useGetText from '@/hooks/useGetText'
import { useTheme } from '@veneer/theme'
import {
  BoosterCardPanel,
  LabelDarkBlock,
  PrintPlanBlock,
  FooterWrapper,
  ChevronRightIcon,
  ErrorButtonsContainer,
  StyledIconPrinterInkDrop
} from './style'
import IconPrinterInkDrop from '@veneer/core/dist/esm/scripts/icons/icon_printer_ink_drop'
import Modal from '@veneer/core/dist/esm/scripts/modal'
import Button from '@veneer/core/dist/esm/scripts/button'
import { findOptionsInkPlan } from '../../api/services'
import { CardLink, CardLinkWrapper } from '@/components/style'
import { useFlags } from '@monetization/hpaip-notification-rules-react'

export default function HpAipMyPlan(props) {
  const getText = useGetText('SubscriptionManagement')
  const flags = useFlags()
  const [isPaperAdded, setIsPaperAdded] = useState<boolean>(false)
  const printerData = props.printerProps
  const printerOptionDetails = printerData?.optionsData
  const handleAddedPaperNotification = props.handleAddedPaperNotification
  const isRemovePaperEnabledFlag = printerData?.isRemovePaper
  const activePaperSubscription = printerData?.activePaperSubscription
  const isPaperEligible = printerData?.paperEligibilityInfo?.enabled
  const subscription = printerData?.root
  const subscriptionId = subscription?.subscriptionId
  const hasUcdeInPath = window?.location?.pathname?.includes('/ucde') || false
  const removeUCDE = hasUcdeInPath ? '/ucde' : ''
  const subscriptionState = printerData?.printer?.state
  const isDisabled =
    subscriptionState === SubscriptionStateEnum.INACTIVE ||
    printerData?.isCancellationTriggered
  const { shippingAddress } = useShippingAddress(printerData?.printer)
  const { priceInfo } = printerData?.paperEligibilityInfo ?? 0
  const isChangeShippingEnabled =
    subscriptionState === SubscriptionStateEnum.ACTIVE
  const graphQlProps = props?.graphQLproductData

  const planDetails = useMemo(() => {
    return findOptionsInkPlan(
      printerOptionDetails?.entities[0]?.edit,
      printerData?.instantInk?.product?.value?.productSku
    )
  }, [printerData?.instantInk?.product?.value?.productSku])

  const optimizedValues = useMemo(() => {
    const amount =
      planDetails?.price !== undefined && planDetails?.leasePrice !== undefined
        ? planDetails?.price + planDetails?.leasePrice
        : 0

    const currentPlanPrice = Math.abs(amount).toFixed(2)
    const totalPlanPrice =
      (parseFloat(currentPlanPrice) + priceInfo).toFixed(2) || 0

    return totalPlanPrice
  }, [planDetails?.price, planDetails?.leasePrice])

  const disabledLinkState = [
    SubscriptionStateEnum.INACTIVE,
    SubscriptionStateEnum.PENDING,
    SubscriptionStateEnum.SUSPENDED
  ]

  const isCancellationInProgress =
    disabledLinkState.includes(subscriptionState) ||
    printerData?.isCancellationTriggered

  const paperPlanChangeUrl = `${removeUCDE}/hp-all-in-print-plan/update-plan`

  const handleRemovePaperClick = () => {
    const paperPlanCancellationUrl = `${removeUCDE}/hp-all-in-print-plan/cancellation/${subscriptionId}?entity=paper`
    window.location.href = paperPlanCancellationUrl
  }
  const handleChangePlan = () => {
    const paperPlanChangeUrl = `${removeUCDE}/hp-all-in-print-plan/update-plan`
    window.location.href = paperPlanChangeUrl
  }

  const handleAddressPlan = () => {
    const paperPlanChangeUrl = `${removeUCDE}/account-details/shipping-billing`
    window.location.href = paperPlanChangeUrl
  }

  const handleAddPaperClick = () => {
    setModalState((prevState) => ({
      ...prevState,
      stepOne: !prevState.stepOne
    }))
  }
  const [modalState, setModalState] = useState({
    stepOne: false,
    stepTwo: false,
    error: false
  })

  const { updateSubscription } = useUpdateSubscription(
    subscriptionId,
    printerData?.paperEligibilityInfo?.optionId,
    subscription?.controllerId
  )

  const handlePaperStepOneSubmit = async () => {
    const success = await updateSubscription()
    setModalState({ stepOne: false, stepTwo: success, error: !success })
  }
  let paperEligibility = ''

  if (isRemovePaperEnabledFlag || isPaperAdded) {
    paperEligibility = 'removePaperService'
  } else if (isPaperEligible) {
    paperEligibility = 'addPaperService'
  }

  return (
    <StyleThemeProvider theme={useTheme()}>
      <BoosterCardPanel>
        {planDetails ? (
          <>
            <PrintPlanBlock isDisabled={isDisabled}>
              <IconPrinterInkDrop size={32} className="iconLabel" />
              <span className="body-large">
                {getText('master-device-detail.print-plan', {
                  defaultValue: 'My plan'
                })}
              </span>
            </PrintPlanBlock>
            <LabelDarkBlock
              isDisabled={isDisabled}
              isSubscriptionHavePaper={activePaperSubscription || isPaperAdded}
            >
              <InkPlanDetails
                t={getText}
                pages={planDetails?.pages}
                rollover={planDetails?.rolloverPages}
                additional={planDetails?.additionalPages}
                additionalPrice={planDetails?.additionalPagesPrice}
                handleaddpaperService={handleAddPaperClick}
                handleremovePaperService={handleRemovePaperClick}
                paperEligibility={paperEligibility}
                isSubscriptionHavePaper={
                  activePaperSubscription || isPaperAdded
                }
              />
            </LabelDarkBlock>

            <FooterWrapper disabled={isCancellationInProgress}>
              <CardLink
                disabled={isCancellationInProgress}
                commonProps={props.commonProps}
                path={paperPlanChangeUrl}
                text={
                  <CardLinkWrapper id="change-plan-button">
                    {getText('ink-plan-details.change-plan', {
                      defaultValue: 'Change Plan'
                    })}
                  </CardLinkWrapper>
                }
              />
              <ChevronRightIcon onClick={handleChangePlan} />
            </FooterWrapper>
            <>
              <AddOnPaperStepOne
                show={modalState.stepOne}
                pricePlan={String(priceInfo)}
                pagesPerMonth={String(planDetails?.pages)}
                onClose={() => setModalState({ ...modalState, stepOne: false })}
                onSubmit={handlePaperStepOneSubmit}
                flags={flags}
              />
              <PaperOnPaasModal
                onClose={() => {
                  handleAddedPaperNotification(true)
                  setModalState({ ...modalState, stepTwo: false })
                  setIsPaperAdded(true)
                }}
                show={modalState.stepTwo}
                pricePlan={String(optimizedValues)}
                pagesPerMonth={String(planDetails?.pages)}
                duration={graphQlProps?.duration}
                shippingAddress={{
                  fullName: `${shippingAddress?.firstName} ${shippingAddress?.lastName}`,
                  address: `${shippingAddress?.address}`,
                  address2: `${shippingAddress?.address2}`,
                  city: `${shippingAddress?.city}`,
                  state: `${shippingAddress?.state}`,
                  postalCode: `${shippingAddress?.postalCode}`,
                  countryCode: `${shippingAddress?.countryCode}`
                }}
                printerName={graphQlProps?.corePlan?.title}
                handleChange={handleAddressPlan}
                changeShippingAddressEnabled={isChangeShippingEnabled}
              />
              <Modal
                align="start"
                closeButton={true}
                show={modalState.error}
                onClose={() => setModalState({ ...modalState, error: false })}
                data-testid="modal-error"
                footer={
                  <ErrorButtonsContainer>
                    <Button
                      appearance="secondary"
                      onClick={handlePaperStepOneSubmit}
                    >
                      {getText('dashboard.error-section.button', {
                        defaultValue: 'Try it again'
                      })}
                    </Button>
                  </ErrorButtonsContainer>
                }
                title={getText('dashboard.error-section.title', {
                  defaultValue: 'Something went wrong'
                })}
              >
                <p>
                  {getText('dashboard.error-section.description', {
                    defaultValue:
                      'It has not been possible to add the Paper Add-on service to your plan'
                  })}
                </p>
              </Modal>
            </>
          </>
        ) : (
          <LoadingHandler
            loading={false}
            error={true}
            customError={
              <CardError
                forceHeight={false}
                icon={<StyledIconPrinterInkDrop size={32} />}
                texts={[
                  <>
                    <p>
                      {getText('device-details.error.title.paragraph-1', {
                        defaultValue: 'Something went wrong.'
                      })}
                    </p>
                    <p>
                      {getText('device-details.error.title.paragraph-2', {
                        defaultValue: "We couldn't load your plan details."
                      })}
                    </p>
                  </>,
                  getText('device-details.error.description', {
                    defaultValue: 'Please refresh the page.'
                  })
                ]}
              />
            }
          ></LoadingHandler>
        )}
      </BoosterCardPanel>
    </StyleThemeProvider>
  )
}
