import FetchDataState from 'src/types/FetchDataState'
import { SubscriptionType } from 'src/types/Subscription'
import { InstantInkSubscriptionInfo } from 'src/types/subscription-response'

export const checkIsSubscriptionEmpty = (
  info: FetchDataState<InstantInkSubscriptionInfo[]>,
  isFetching: boolean,
  data: SubscriptionType | null,
  error: boolean
): boolean => {
  if (isFetching || info?.isFetching) return false // Data is being fetched
  if (error || info?.error) return false // Error state
  if (!data && (!info?.data || info.data.length === 0)) return true // No data
  if (
    Array.isArray(data) &&
    data.length === 0 &&
    (!info?.data || info.data.length === 0)
  )
    return true

  return false // Otherwise, not empty
}
