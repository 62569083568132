import { tokens } from '@veneer/tokens'
import styled from 'styled-components'
import { InternalLink } from './InternalLinks/InternalLinks'

const { highContrastGray } = tokens.color
const { size5 } = tokens.layout

export const CardLink = styled(InternalLink)`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  color: ${({ disabled }) => (disabled ? highContrastGray : '#0278ab')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:hover {
    text-decoration: none;
  }

  &:focus {
    border: 1px solid #0278ab;
    outline: none;
  }
`

export const CardLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  line-height: ${size5};
  font-size: 14px;
`
