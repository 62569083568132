import React, { useEffect, useMemo } from 'react'
import useGetText from '@/hooks/useGetText'
import '@veneer/core/dist/css/veneer.css'
import { useTheme } from '@veneer/theme'
import { ThemeProvider as StyleThemeProvider } from 'styled-components'
import { Container, EmptyState, Heading } from './styles'
import useInstantInkSubscriptionInfo from '@/hooks/useInstantInkSubscriptionInfo'
import useGetAllSubscriptions from '@/hooks/useGetAllSubscription'
import { checkIsSubscriptionEmpty } from '@/utils/checkIsSubscriptionEmpty'
import { MfePropsType } from 'src/types/mfeProps'
import InitialComponent from '../InitialComponent'
import {
  publishEvent,
  SubscriptionScreenDisplayed
} from '../../utils/analytics'
import { CommonProps } from 'src/types/subscription-response'
import { useFlags } from 'launchdarkly-react-client-sdk'

const SubscriptionManagementRoot = ({
  authProvider,
  stack,
  navigation
}: MfePropsType) => {
  const flags = useFlags()
  const getText = useGetText('SubscriptionManagement')
  const theme = useTheme()

  const {
    data: subscriptionData,
    isFetching: hpOneIsFetching,
    error: subscriptionError
  } = useGetAllSubscriptions(authProvider, stack)
  const { info } = useInstantInkSubscriptionInfo()

  const isLoading = hpOneIsFetching || info.isFetching

  const shouldDisplayEmptyState = checkIsSubscriptionEmpty(
    info,
    hpOneIsFetching,
    subscriptionData,
    subscriptionError
  )

  const commonProps: CommonProps = {
    hpais: subscriptionData,
    iInk: info?.data,
    subError: subscriptionError,
    iiError: info?.error,
    authProvider: authProvider,
    stack: stack,
    isFetching: info?.isFetching,
    navigation: navigation
  }

  const renderContent = useMemo(() => {
    console.log('LD flags', Object.keys(flags).length)
    if (shouldDisplayEmptyState)
      return <EmptyState>{getText('emptyState')}</EmptyState>
    return (
      <>
        {!isLoading && Object.keys(flags).length !== 0 && (
          <InitialComponent commonProps={commonProps} />
        )}
      </>
    )
  }, [flags, isLoading, shouldDisplayEmptyState])

  useEffect(() => {
    publishEvent(SubscriptionScreenDisplayed)
  }, [])

  return (
    <StyleThemeProvider theme={theme}>
      <Container>
        <Heading role="heading" className="title-small">
          {getText('heading')}
        </Heading>
        {renderContent}
      </Container>
    </StyleThemeProvider>
  )
}

export default SubscriptionManagementRoot
