import IconPrinter from '@veneer/core/dist/esm/scripts/icons/icon_printer'
import { tokens } from '@veneer/tokens'
import styled, { css } from 'styled-components'
const { white, highContrastGray, gray6 } = tokens.color
const { size4, size1, lineHeight4, lineHeight2 } = tokens.typography
import { Card } from '@monetization/hpaip-ui-shared-components'
const { smMax, smMin, size6: layoutSize6 } = tokens.layout

const { size0: layoutSize0, size4: layoutSize4, cornerRadius3 } = tokens.layout
interface StyledCardProps {
  $disabled?: boolean
}
const disabledStyle = css`
  && {
    pointer-events: none;
    color: ${highContrastGray};
    img {
      opacity: 0.5;
    }

    * {
      color: ${highContrastGray};
    }
  }
`

export const StyledCard = styled('div')<StyledCardProps>`
  padding: ${layoutSize0} ${layoutSize0} ${layoutSize4};
  background: ${white};
  border-radius: ${cornerRadius3};

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  div section > header {
    margin-bottom: 0;
  }
  ${(props) => (props.$disabled ? disabledStyle : null)}

  section {
    > header {
      > h2 {
        font-size: ${size4};
        line-height: ${lineHeight4};
      }
    }
    > article {
      @media screen and (min-width: 576px) {
        > div {
          height: auto;
        }
      }
      > div > button {
        font-size: ${size1};
        line-height: ${lineHeight2};
      }
    }
  }
`

export const StyledIconPrinter = styled(IconPrinter)`
  && {
    color: ${gray6};
  }
`

export const DarkCard = styled(Card)`
  background-color: #f8f8f8 !important;
  box-sizing: border-box !important;
`

export const StyledDeviceDetailsCard = styled(DarkCard)`
  @media screen and (max-width: ${smMax}) {
    display: block;
  }
  min-height: 296px;
`

type StyledCardContainerProps = {
  $isLoading?: boolean
}

export const StyledCardContainer = styled(
  StyledDeviceDetailsCard
)<StyledCardContainerProps>`
  && {
    padding: ${layoutSize6};
    background: #f8f8f8 !important;
    min-height: 296px;
    display: flex;
    flex-grow: 1;
    height: 100%;
    ${({ $isLoading }) =>
      $isLoading
        ? 'padding: 0;'
        : `@media screen and (max-width: ${smMin}) {
            display: flex;
            flex-direction: column;
          }`}
  }
`

export const DeviceDetailStyles = styled.div`
  margin-top: -18px;
`
